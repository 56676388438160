:root {
  --color-primary: #415a77ff;
  --color-secondary: #415a77ff;
  --color-secondary-darker: #737373;
  --color-primary-bg: #e0e1ddff;
  --color-selected: #d33e43;
  --color-selected-secondary: rgba(213, 64, 31, 255);
  --color-exotic: #d1c238

  --gradient-primary: linear-gradient(to top left, #39b385, #9be15d);
  --gradient-secondary: linear-gradient(to top left, #ffb003, #ffcb03);
  --rich-black-fogra-29: #0d1b2aff;
  --oxford-blue: #1b263bff;
  --bdazzled-blue: #415a77ff;
  --shadow-blue: #778da9ff;
}
