@import "colors.css";

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: "Ubuntu", sans-serif;
  font-size: 1.3rem;
  min-height: 100% !important;
  line-height: 1.8rem;
}

* {
  box-sizing: inherit;
}
h1 {
  font-size: 1.5rem;
}
html {
  font-size: 62.5%;
  box-sizing: border-box;
}

canvas {
  display: block;
}
span {
}

a {
  color: var(--color-primary-bg);
  font-size: 2rem;
  font-weight: bold;
}

/* ---- particles.js container ---- */

#particles-js {
  width: 100%;
  height: 100rem;
  background-color: #415a77ff;
  /* background-image: url(""); */
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: repeat;
  position: relative;
  background-size: contain;
}
#content-box {
  /* display: none; */
  position: absolute;
  overflow: auto;
  z-index: 100;
  top: 5%;
  right: 50%;
  transform: translate(50%, 0%);
  padding: 1rem 1.8rem 4rem 1.5rem;
  color: #e0e1ddff;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 1.3rem;
  box-shadow: var(--color-primary-bg) 0rem 0rem 1rem 0.1rem;
  /* filter: blur(3px); */
  /* transition: all 3s ease-in-out; */
}
.logo {
  width: 20rem;
  filter: opacity(100%)
    drop-shadow(-3.7rem 3.5rem 3.5rem var(--color-primary-bg));
  transition: all 0.8s;
  animation: shrink 3s ease-in-out;
}
.pdiv {
  /* margin-top: 0.3rem; */
  padding: 0rem;
  border-radius: 2.9rem;
  background-color: rgb(255, 255, 255, 0.1);
}
.spanBig {
  font-size: 3rem;
  border-radius: 1rem 1rem 1rem 1rem;
  cursor: default;
  line-height: 2.5;
  color: #e0e1ddff;
  background-color: rgb(255, 255, 255, 0.1);
  padding: 1.5rem;
}

.header-df {
  display: flex;
  justify-content: space-between;
}
@keyframes rotateFinite {
  0% {
    transform: rotate(0);
  }
  80% {
    transform: rotate(420deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes shrink {
  0% {
    transform: scale(0.1, 0.1);
  }
  20% {
    transform: scale(0.2, 0.2);
  }
  30% {
    transform: scale(0.1, 0.1);
  }
  40% {
    transform: scale(0.2, 0.2);
  }
  50% {
    transform: scale(0.1, 0.1);
  }
  90% {
    transform: scale(1, 1);
  }
  95% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes slide {
  0% {
    transform: translateX(0%);
  }
  90% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(100%);
  }
}

.rotate {
  transition: all 1s;
  animation: rotateFinite 1s ease-out;
}
.vanish {
  transition: all 1s ease-in-out;
  /* animation: slide 1s ease-in-out; */
}
.content-container {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 90rem;
  /* height: fit-content; */
  min-height: 20rem;
  /* padding: 0.2rem; */
}
.pic {
  display: flex;
  justify-content: space-between;
}
.obr2 {
  width: 11rem;
  min-height: 100%;
  filter: sepia(19%);
  position: absolute;
  top: 0;
  right: 0;
}
.obr {
  width: 11rem;

  opacity: 0%;
}

.textik {
  padding: 2rem;
}
.boxes {
  position: absolute;

  width: 90rem;
  top: 0rem;
  left: 0rem;
  transform: translate(0%, 0%);
  /* padding: 0rem 0rem; */
  overflow: hidden;
}
.overall-container {
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 2.2rem;
}
.arrow_div {
  align-self: top;
  text-align: left;
  min-width: 1.5rem;
  padding: 0.5rem 0rem 0rem 0rem;
  /* gap: 2rem; */
}
.hidden {
  display: none;
}
.greyed {
  filter: opacity(0%) !important;
}
.arrow {
  filter: opacity(80%);
}
.icologo {
}
.spanSmall {
  font-size: 1.9rem;
  cursor: pointer;
  border-radius: 1.5rem;
  color: #d1c238;
  /* border-bottom: 0.2rem solid var(--color-primary-bg); */
}

.tab {
  padding: 1.2rem;
  line-height: 2rem;
  font-weight: bold;
  /* background-color: var(--color-primary-bg); */
  margin: 0 0rem 0 0;
  transition: all 0.5s;
  animation: shrink 0.5s ease-in-out;
}
.flags {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}
.languages {
  /* filter: grayscale(69%); */
  filter: sepia(69%);
}
.tab_container {
  position: relative;
  margin: 0;
  display: flex;
  gap: 0rem;
}
.text_container {
  position: relative;
  margin: 0;
  display: flex;
  gap: 0rem;
}
.clona-svg10 {
  position: absolute;
  border-radius: 50%;
  cursor: pointer;
  height: 2.7rem;
  width: 2.7rem;
  background-color: #415a77ff;
  filter: opacity(40%);
  border: 0rem solid #415a77ff;
  z-index: 200;
}
.svg-10 {
  position: absolute;
  border-radius: 50%;
  cursor: pointer;
  height: 2.7rem;
  width: 2.7rem;
  border: 0.2rem solid var(--color-primary-bg);
}
.clona-all {
  position: relative;
  display: block;
  min-width: 2.7rem;
}
.hidden_content_box {
  filter: opacity(0%);
  position: relative !important;
}

@media (max-width: 1200px) {
  #content-box {
    max-width: 900px;
  }
  .content-container {
    width: 600px;
  }
  .boxes {
    width: 600px;
  }
  .obr {
    width: 80px;
  }
  .obr2 {
    width: 80px;
  }
  #particles-js {
    height: 1500px;
  }
}
@media (max-width: 800px) {
  #content-box {
    width: 100%;
    top: 0px;
    left: 0px;
    transform: translate(0%, 0%);
  }
  .content-container {
    width: 100%;
  }
  .boxes {
    width: 100%;
  }
  .obr {
    width: 0px;
  }
  .obr2 {
    width: 0px;
  }

  .spanBig {
    font-size: 1.5rem;
    line-height: 1.8rem;
    padding: 1rem;
  }
  .spanSmall {
    font-size: 1rem;
    border-radius: 1rem;
    color: #d1c238;
  }
  .tab {
    padding: 0.8rem;
    line-height: 1.2rem;
    margin: 0 0rem 0 0;
    transition: all 0.5s;
    animation: shrink 0.5s ease-in-out;
  }
  .arrow_div {
    display: none;
  }
  .overall-container {
    padding-top: 0rem;
    justify-content: center;
    gap: 0;
  }
  #particles-js {
    height: 1500px;
  }
}
